import ToolbarComponent from './Toolbar.component';

const Toolbar: React.FC = () => {



  return (
<ToolbarComponent></ToolbarComponent>
    )
}

export default Toolbar